import { getAccountantsAdvisersCards } from 'page-data/business';
import { useGatsbyImage } from 'hooks/useGatsbyImage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { SignupLink } from 'components/Links';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { PricingSlider } from 'components/PricingSlider';
import { ImageCards } from 'components/ImageCard';

import { TrustPilot } from 'components/TrustPilot';

import type { FC } from 'react';

export const Business: FC = () => {
  const accountantsImage = useGatsbyImage({
    name: 'business/keyboard',
    alt: 'Illustration of a keyboard',
  });

  const financialAdvisersImage = useGatsbyImage({
    name: 'business/coins',
    alt: 'Illustration of gold coins',
  });

  const accountantsAdvisersCards = getAccountantsAdvisersCards(
    accountantsImage,
    financialAdvisersImage
  );

  return (
    <Layout hideFooterCta>
      <Seo />

      <HeroContainer skipColoration>
        <Section span={2}>
          <h1>Share and manage all your client portfolios</h1>
          <p>
            Automatically track trades, dividend payments/corporate actions and performance across
            all your client’s portfolios.
          </p>

          <br />

          <SignupLink asButton appearance="primary" showArrowIcon />
        </Section>

        <SectionImage
          span={2}
          image={useGatsbyImage({
            name: 'business/hero',
            loading: 'eager',
            alt: "User Interface of Sharesight Professional's portfolio and dividend tracker for accountants and financial advisers",
          })}
          boxShadow={false}
        />
      </HeroContainer>

      <Container>
        <ImageCards cards={accountantsAdvisersCards} gap={15} />
      </Container>
      <Container>
        <SectionImage
          image={useGatsbyImage({
            name: 'business/multiple-portfolios',
            alt: "Screenshot of Sharesight's reports page on laptop screen",
          })}
          boxShadow={false}
        />
        <Section>
          <h2>Set up an easily shareable dashboard for all your clients</h2>
          <p>
            Quickly check any client portfolio at any time. We automate the tedious tasks so you can
            focus on growing your business.
          </p>
        </Section>
      </Container>

      <PricingSlider />

      <Container background="cream" skipColoration>
        <SectionImage
          image={useGatsbyImage({
            name: 'business/permissions',
            alt: "Screenshot of Sharesight's permissions page",
          })}
          boxShadow={false}
        />
        <Section>
          <h2>Permissions made easy</h2>
          <p>
            Sharing any portfolio to a client, your staff etc. Sharesight offers a pain-free feature
            to manage everything in one place under the portfolios menu.
          </p>
        </Section>
      </Container>

      <TrustPilot />

      <Container background="navyBlueHighlight">
        <Section>
          <h2>Automate your practice with a 30-day free trial</h2>
          <p>No credit card needed.</p>
          <br />
          <SignupLink asButton appearance="primary" containerBackground="negative" showArrowIcon />
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'business/cta',
            alt: 'Number 30 with stock tickers surrounding it',
          })}
          span={2}
          boxShadow={false}
        />
      </Container>
    </Layout>
  );
};

export default Business;
