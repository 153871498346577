import { FlexContainer } from 'components/FlexContainer';
import type { ReactNode } from 'react';
import type { ImageCardProps } from 'components/ImageCard';

export const getAccountantsAdvisersCards = (
  accountantsImage: ReactNode,
  financialAdvisersImage: ReactNode
): ImageCardProps[] => [
  {
    title: 'Accountants',
    content: (
      <>
        Save time with automated dividend tracking and comprehensive tax reporting at the click of a
        button.
      </>
    ),
    background: 'navyBlueHighlight',
    image: (
      <FlexContainer justifyContent="center" alignItems="center">
        {accountantsImage}
      </FlexContainer>
    ),
    to: '/accountants/',
    showButton: true,
  },
  {
    title: 'Financial advisers',
    content: (
      <>
        Deepen client relationships. Reveal their real-time performance in one easy to share
        dashboard.
      </>
    ),
    background: 'yellowHighlight',
    image: (
      <FlexContainer justifyContent="center" alignItems="center">
        {financialAdvisersImage}
      </FlexContainer>
    ),
    to: '/financial-advisers/',
    showButton: true,
  },
];
