import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { proPricingPlans } from 'page-data/pricing';

import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { SliderDot, SliderContainer, SliderSpacer, SliderLine, SliderBlank } from './Slider.styled';
import { Container } from './Container.styled';
import { Entry, EntryText } from './Entry.styled';
import { Price } from './Price.styled';
import { Count } from './Count.styled';
import { TextContainer, Text, PortfolioText } from './Text.styled';

import type { FC } from 'react';
import type { LocaleProPlan, ProPlan } from 'page-data/pricing';

const planOptions: ProPlan[] = ['5-19', '20-49', '50-99', '100+'];

export const Slider: FC = () => {
  const {
    translations: { currencyCode, currencySymbol },
  } = useContentfulLocale();

  const {
    currentLocale: { id },
  } = useLocalizationContext();

  // Get localized pricing plans
  const localizedPlans: LocaleProPlan = proPricingPlans[id];

  const entries = planOptions.map((planOption, index) => (
    <Entry key={planOption}>
      <Price>
        {currencySymbol}
        {localizedPlans[planOption].price}
      </Price>
      <SliderSpacer>
        {index === 0 ? <SliderBlank /> : <SliderLine color="black" />}
        <SliderDot />
        {index === planOptions.length - 1 ? <SliderBlank /> : <SliderLine color="black" />}
      </SliderSpacer>
      <Count>{planOption}</Count>
      <EntryText>Portfolios</EntryText>
    </Entry>
  ));

  return (
    <Container>
      <TextContainer>
        <Text>{currencyCode} per portfolio/per month*</Text>
        <PortfolioText>Portfolios</PortfolioText>
      </TextContainer>
      <SliderContainer>{entries}</SliderContainer>
    </Container>
  );
};
