import styled from 'styled-components';
import type { Color } from 'styled/types';

/**
 * Puts the "line" in Timeline. \
 * A component for rendering a line with elements running along it.
 *
 * eg: the `Timeline` and `PricingSlider`
 */
export const Line = styled.div<{ color?: Color }>`
  background: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.orange};
  z-index: 1;
  height: 2px;
  overflow: visible;
  flex-direction: row;
  display: flex;
`;
