import styled from 'styled-components';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;
  flex: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex: 1;
  }
`;

export const Text = styled.p`
  margin: 0;
`;

export const PortfolioText = styled.p`
  font-weight: bold;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;
