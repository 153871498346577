import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Box } from 'components/Box';
import { getTaxType } from 'helpers/getTaxType';
import { Container } from './Container.styled';
import { TextContainer } from './TextContainer.styled';
import { Slider } from './Slider';

import type { FC } from 'react';

export const PricingSlider: FC = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const {
    priceIncludesTax,
    translations: { currencyCode },
  } = useContentfulLocale();

  let taxText = `*Prices in ${currencyCode}`;
  const taxType = getTaxType(currencyCode);
  taxText += !priceIncludesTax ? '. Taxes may apply.' : ` excluding ${taxType}.`;

  return (
    <Box
      padding={isMobile ? 10 : 15}
      paddingLeft={isMobile ? 5 : 12}
      paddingRight={isMobile ? 5 : 12}
    >
      <Container id="pricing">
        <TextContainer>
          <h2>Pay as you need pricing</h2>
          <Box marginBottom={0} maxWidth={isMobile ? '100%' : '350px'}>
            <p>A plan that can scale with your business.</p>
            <p>
              <em>{taxText}</em>
            </p>
          </Box>
        </TextContainer>
        <Slider />
      </Container>
    </Box>
  );
};
